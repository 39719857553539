import {
  AddCustomerPayload,
  AddCustomerResponse,
  BankAccountPayload,
  BankAccountListInterface,
  BankNameVerificationPayload,
  BankNameVerificationResponse,
  BillingInfoResponse,
  BusinessBillingInterface,
  BussinessWalletResponse,
  FetchCustomersResponse,
  ListOfBanksResponse,
  MasterWalletBalanceResponse,
  NewWalletPayloadInterface,
  SendMoneyPayload,
  SettlementBankAccountPayload,
  SweepCustomersWalletPayload,
  TriggerOTPPayload,
  VerifyOTPPayload,
  VirtualAccountPayload,
  VirtualAccountsProviderPayload,
  VirtualAccountsResponse,
  VirtualAccountResponse,
  WalletTransactionResponse,
  WalletTransferPayload,
  WalletV2Response,
  WalletWithdrawalPayload,
  AddPreferenceResponse,
  NotificationsPayload,
  BankBody,
  TransferFundsPayload,
  IP2pPayload
} from "./../../interfaces/wallet";
import config from "config";
import { client } from "lib/client";
import { DashboardSummaryInterface } from "interfaces/users";
import {
  FilterInterface,
  TransactionsFilterInterface,
  ComplexTransactionsFilterInterface,
  CompoundTransactionsFilterInterface
} from "interfaces/filter";
import { PaginationApiResponse } from "interfaces/pagination";
import {
  BulkPayoutJSONModel,
  ReinitiatePayoutPayload
} from "interfaces/payout";

async function getMasterWallet(businessId: string) {
  const response = await client<MasterWalletBalanceResponse, object>(
    `${config.API_ENDPOINT}wallets/balance/master?business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function getV2Wallets(businessId: string) {
  const response = await client<WalletV2Response, object>(
    `${config.API_ENDPOINT}wallets/v2?business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function getWalletTransaction(
  walletRef: string,
  businessId: string,
  params?: ComplexTransactionsFilterInterface
) {
  const response = await client<
    {
      transactions: WalletTransactionResponse[];
      meta: PaginationApiResponse;
    },
    ComplexTransactionsFilterInterface
  >(
    `${config.API_ENDPOINT}wallets/transactions?wallet_ref=${walletRef}&business_id=${businessId}`,
    "GET",
    { params }
  );
  return response;
}

async function getDefaultBusiness(businessId: string) {
  const response = await client<any, object>(
    `${config.API_ENDPOINT}merchants/businesses/${businessId}?expand=true`,
    "GET"
  );
  return response;
}

async function getTransferFee(businessId: string) {
  const response = await client<BillingInfoResponse, object>(
    `${config.API_ENDPOINT}merchants/businesses/billing/${businessId}`,
    "GET"
  );
  return response;
}

async function getBusinessTransaction(
  _: unknown,
  businessId: string,
  params?: TransactionsFilterInterface
) {
  if (params?.status) {
    params.status = params?.status.replace("unsuccessful", "failed");
  }
  const filterParams = params;

  const response = await client<
    {
      transactions: WalletTransactionResponse[];
      meta: PaginationApiResponse;
    },
    TransactionsFilterInterface
  >(
    `${config.API_ENDPOINT}wallets/transactions?business_id=${businessId}`,
    "GET",
    { params: filterParams || {} }
  );
  return response;
}

async function getWalletSummary(businessId: string) {
  const response = await client<DashboardSummaryInterface, object>(
    `${config.API_ENDPOINT}wallets/summary?business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function getWalletDetails(walletRef: string, businessId: string) {
  const response = await client<BussinessWalletResponse, object>(
    `${config.API_ENDPOINT}wallets/${walletRef}?business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function getListOfBanks(businessId: string) {
  const response = await client<ListOfBanksResponse[], object>(
    `${config.API_ENDPOINT}merchants/utilities/banks/list?country=Nigeria&business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function getBusinessBilling(businessId: string) {
  const response = await client<BusinessBillingInterface, object>(
    `${config.API_ENDPOINT}merchants/businesses/billing/${businessId}`,
    "GET"
  );
  return response;
}

async function getBusinessWallets(
  businessId: string,
  params?: FilterInterface
) {
  const response = await client<
    {
      data: BussinessWalletResponse[];
      meta: PaginationApiResponse;
    },
    FilterInterface
  >(`${config.API_ENDPOINT}wallets?business_id=${businessId}`, "GET", {
    isHeader: false,
    params
  });
  return response;
}

async function getCustomers(businessId: string) {
  const response = await client<
    {
      data: FetchCustomersResponse[];
      meta: PaginationApiResponse;
    },
    object
  >(`${config.API_ENDPOINT}wallets/customers?business_id=${businessId}`, "GET");
  return response;
}

async function getVirtualAccounts(payload: VirtualAccountPayload) {
  const response = await client<VirtualAccountResponse, VirtualAccountPayload>(
    `${config.API_ENDPOINT}virtual-accounts`,
    "POST",
    {
      body: { ...payload, provider: "wema" }
    }
  );
  return response;
}

async function createBankAccount(payload: BankAccountPayload) {
  const response = await client<
    { data: BankAccountListInterface },
    BankAccountPayload
  >(`${config.API_ENDPOINT}merchants/businesses/bank-account`, "POST", {
    body: payload
  });
  return response;
}

async function editBankAccount(body: BankBody) {
  const payload: BankAccountPayload = {
    bank_name: body.bank_name,
    account_name: body.account_name,
    is_settlement_account: true,
    business_id: body.business_id,
    account_number: body.account_number,
    bank_code: body.bank_code
  };
  const response = await client<
    { data: BankAccountListInterface },
    BankAccountPayload
  >(
    `${config.API_ENDPOINT}merchants/businesses/bank-account/${body.id}`,
    "PATCH",
    {
      body: payload
    }
  );
  return response;
}

async function addSettlementAccount(payload: SettlementBankAccountPayload) {
  const response = await client<any, SettlementBankAccountPayload>(
    `${config.API_ENDPOINT}merchants/businesses`,
    "PATCH",
    {
      body: payload
    }
  );
  return response;
}

async function sendMoney(payload: SendMoneyPayload) {
  const response = await client<
    { data: { [key: string]: string } },
    SendMoneyPayload
  >(`${config.API_ENDPOINT}wallets/transfer`, "POST", {
    body: { ...payload },
    isHeader: false
  });
  return response;
}
async function reinitiatePayout(payload: ReinitiatePayoutPayload) {
  const response = await client<
    { data: { [key: string]: string } },
    ReinitiatePayoutPayload
  >(`${config.APPROVAL_POLICIES_API}v1/approval/payouts/re-initiate`, "POST", {
    body: { ...payload },
    isHeader: false
  });
  return response;
}

async function bulkPayoutJson(payload: BulkPayoutJSONModel) {
  const response = await client<
    { data: { [key: string]: unknown } },
    BulkPayoutJSONModel
  >(`${config.API_ENDPOINT}recipients/bulk-recipients?type=json`, "POST", {
    body: { ...payload },
    isHeader: false
  });
  return response;
}

async function sendMoneySchedule(payload: SendMoneyPayload) {
  const response = await client<
    { data: { [key: string]: string } },
    SendMoneyPayload
  >(`${config.API_ENDPOINT}wallets/schedule-payment`, "POST", {
    body: { ...payload },
    isHeader: false
  });
  return response;
}

async function triggerOTP(payload: TriggerOTPPayload) {
  const response = await client<any, TriggerOTPPayload>(
    `${config.API_ENDPOINT}merchants/profile/send-otp`,
    "POST",
    {
      body: payload,
      isHeader: false
    }
  );
  return response;
}

async function verifyOTP(payload: VerifyOTPPayload) {
  const response = await client<any, VerifyOTPPayload>(
    `${config.API_ENDPOINT}merchants/profile/verify-otp`,
    "POST",
    {
      body: payload,
      isHeader: false
    }
  );
  return response;
}

async function resolveBankName(payload: BankNameVerificationPayload) {
  const response = await client<
    BankNameVerificationResponse,
    BankNameVerificationPayload
  >(`${config.API_ENDPOINT}merchants/utilities/banks/verify`, "POST", {
    body: payload
  });
  return response;
}

async function merchantWithdrawal(payload: WalletWithdrawalPayload) {
  const response = await client<
    { data: { [key: string]: unknown } },
    WalletWithdrawalPayload
  >(`${config.API_ENDPOINT}wallets/merchant-withdrawal`, "POST", {
    body: payload,
    isHeader: false
  });
  return response;
}

async function transferFundsToAccount(payload: TransferFundsPayload) {
  const response = await client<
    { data: { [key: string]: unknown } },
    TransferFundsPayload
  >(`${config.API_ENDPOINT}wallets/transfer`, "POST", {
    body: payload,
    isHeader: false
  });
  return response;
}

async function walletTransfer(payload: WalletTransferPayload) {
  const response = await client<
    { data: { [key: string]: unknown } },
    WalletTransferPayload
  >(`${config.API_ENDPOINT}wallets/transfer-p2p`, "POST", {
    body: payload,
    isHeader: false
  });
  return response;
}

async function sweepCustomersWallet(payload: SweepCustomersWalletPayload) {
  const response = await client<
    { data: { [key: string]: unknown } },
    SweepCustomersWalletPayload
  >(`${config.API_ENDPOINT}wallets/customer-master-bulk-withdrawal`, "POST", {
    body: payload,
    isHeader: false
  });
  return response;
}

async function addBusinessPreference<T extends {}>(payload: T) {
  const response = await client<{ data: AddPreferenceResponse }, T>(
    `${config.API_ENDPOINT}merchants/businesses/preference`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

async function updateBusinessPreference(payload: NotificationsPayload) {
  const response = await client<
    { data: AddPreferenceResponse },
    NotificationsPayload
  >(
    `${config.API_ENDPOINT}merchants/businesses/preference/${payload?.preference_id}`,
    "PATCH",
    {
      body: payload
    }
  );
  return response;
}

async function getBusinessDetails(businessId: string) {
  const response = await client<any, object>(
    `${config.API_ENDPOINT}merchants/businesses/${businessId}`,
    "GET"
  );
  return response;
}

async function getBankAccounts(businessId: string) {
  const response = await client<
    { accounts: BankAccountListInterface[] },
    object
  >(
    `${config.API_ENDPOINT}merchants/businesses/bank-account?business_id=${businessId}`,
    "GET"
  );
  return response;
}

async function toggleBusinessPortalActivation(businessId: string) {
  const response = await client<any, any>(
    `${config.API_ENDPOINT}merchants/businesses/toggle-portal/${businessId}`,
    "PATCH",
    {
      body: {}
    }
  );
  return response;
}

async function getVirtualAccountProviders(
  payload: VirtualAccountsProviderPayload
) {
  const response = await client<
    VirtualAccountsResponse,
    VirtualAccountsProviderPayload
  >(`${config.API_ENDPOINT}bank-adapters/virtual-accounts`, "POST", {
    body: payload
  });

  return response;
}

async function createNewWallet(payload: NewWalletPayloadInterface) {
  const response = await client<
    { data: BussinessWalletResponse },
    NewWalletPayloadInterface
  >(`${config.API_ENDPOINT}wallets`, "POST", {
    body: payload,
    isHeader: false
  });

  return response;
}

async function downloadTransaction({
  businessId,
  walletRef
}: {
  businessId: string;
  walletRef: string;
}) {
  const response = await client<string, object>(
    `${config.API_ENDPOINT}wallets/transactions/download?business_id=${businessId}&wallet_ref=${walletRef}`,
    "PATCH",
    {
      body: {}
    }
  );

  return response;
}

async function downloadAllTransaction({
  businessId,
  walletRef,
  email,
  filters,
  showBalance
}: {
  businessId: string;
  email: string;
  walletRef?: string;
  filters: CompoundTransactionsFilterInterface;
  showBalance?: boolean;
}) {
  if (
    !filters?.amount &&
    !filters?.category &&
    !filters?.channel &&
    !filters?.status &&
    !filters?.type
  ) {
    filters = { ...filters, category: "fee,agent,bank_transfer,invoice" };
  }

  const filterOption: CompoundTransactionsFilterInterface = filters;

  delete filterOption.perPage;

  const payload: { email: string } = {
    email
  };

  const response = await client<
    string & { message: string },
    CompoundTransactionsFilterInterface | { email: string }
  >(
    `${
      config.API_ENDPOINT
    }wallets/transactions/download?business_id=${businessId}${
      Boolean(walletRef) ? "&wallet_ref=" + walletRef : ""
    }`,
    "PATCH",
    {
      body: payload,
      params: showBalance
        ? { ...filterOption, pageCount: 2000, page: 1, limit: 2000 }
        : {
            ...filterOption,
            page: undefined,
            pageCount: undefined,
            limit: undefined
          }
    }
  );

  return response;
}

async function addCustomer(payload: AddCustomerPayload) {
  const response = await client<AddCustomerResponse, AddCustomerPayload>(
    `${config.API_ENDPOINT}wallets/customers`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

async function updateBankAccount(payload: BankAccountPayload) {
  const response = await client<
    { data: BankAccountListInterface },
    BankAccountPayload
  >(
    `${config.API_ENDPOINT}merchants/businesses/bank-account/${payload.account_id}`,
    "PATCH",
    {
      body: payload
    }
  );
  return response;
}

async function p2pTransfer(payload: IP2pPayload) {
  const response = await client<any, IP2pPayload>(
    `${config.API_ENDPOINT}wallets/transfer-p2p`,
    "POST",
    {
      body: payload
    }
  );
  return response;
}

export {
  getMasterWallet,
  getV2Wallets,
  getWalletTransaction,
  getVirtualAccounts,
  walletTransfer,
  getBusinessTransaction,
  getBusinessWallets,
  getWalletDetails,
  getCustomers,
  merchantWithdrawal,
  getListOfBanks,
  resolveBankName,
  getTransferFee,
  getVirtualAccountProviders,
  createNewWallet,
  getDefaultBusiness,
  downloadTransaction,
  getBusinessBilling,
  triggerOTP,
  verifyOTP,
  downloadAllTransaction,
  addBusinessPreference,
  updateBusinessPreference,
  toggleBusinessPortalActivation,
  sendMoney,
  getWalletSummary,
  getBusinessDetails,
  sweepCustomersWallet,
  addCustomer,
  createBankAccount,
  addSettlementAccount,
  getBankAccounts,
  updateBankAccount,
  editBankAccount,
  sendMoneySchedule,
  bulkPayoutJson,
  transferFundsToAccount,
  reinitiatePayout,
  p2pTransfer
};
